// ===============================
//
// Base
//
// ===============================

	//
	// Body
	//
	
		body {
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			text-rendering: optimizeLegibility;
		}
		
		::selection {
			color: $selection-color;
			background-color: $selection-background-color;
		}
	
	//
	// Links
	//

		a,
		button {
			transition: $global-transition;
			&:focus, 
			&:active, 
			&:hover {
				outline: none !important;
			}
		}
	
	//
	// Typography
	//
	
		h1, h2, h3, h4, h5, h6 {	
		}
	
		
		
	//
	// Animations
	//
	
		@keyframes animationGradient {
			0% {
				background-position: 0% 50%;
			}
			50% {
				background-position: 100% 50%;
			}
			100% {
				background-position: 0% 50%;
			}
		}