.hero {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	background-color: get-color(darkGrey);
	overflow: hidden;
	&-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		background-color: rgba(get-color(black), 0.5);
	}
	&-image {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
			animation: zoomIn 20s linear alternate infinite;	
		}
	}
	&-content {
		width: 100%;
		position: relative;
		display: inline-block;
		z-index: 3;
	}
	&-heading {
		
	}
	&-button {
		
	}
	&.size-large {
		height: 100vh;
		.hero {
			&-content {
				position: absolute;
				top: 50%;
				transform: translatey(-50%);
			}
		}
	}
	&.type-image-only {
		.hero {
			&-image {
				position: relative;
				height: rem-calc(500);
			}
		}
	}
}