.social-share {
	&-heading {
		display: block;
		@extend h5;
	}
	a {
		display: inline-flex;
		align-items: center;
		position: relative;
		justify-content: center;
		font-size: rem-calc(12);
		color: get-color(white);
		width: rem-calc(40);
		height: rem-calc(40);
		border-radius: 100%;
		background-color: get-color(darkGrey);
		margin-right: rem-calc(8);
		i {
			position: relative;
			z-index: 2;
		}
		&:after {
			content: '';
			width: 100%;
			height: 100%;
			z-index: 0;
			position: absolute;
			top: 0;
			right: 0;
			border-radius: 100%;
			z-index: 1;
			transition: all 0.3s get-easing(in-out-circ);
		}
		&:after {
			background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
			background-size: 200% 200%;
			animation: gradient 4s alternate infinite;
			clip-path: circle(0% at 20% -40%);
		}
		&:hover {
			transform: scale(0.9);
			&:after {
				clip-path: circle(150% at 20% -40%);
			}
		}
	}
}
