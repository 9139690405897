.blog-items {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	&:before {
		content: normal;
	}
	&.type-single-row {
		@include breakpoint(medium down) {
			.blog-item:last-child {
				display: none;
			}
		}
	}
}
.blog-item {
	@extend .column;
	@extend .small-12;
	@extend .medium-6;
	@extend .large-4;
	margin-bottom: $global-margin * 4;
	opacity: 1.0;
	@include breakpoint(small down) {
		margin-bottom: $global-margin * 2;
	}
	&.is-loading {
		opacity: 0.0;
	}
	&-info {
		display: flex;
		margin-bottom: $global-margin / 2;
		position: relative;
		z-index: 1;
		transition: $global-transition;
		transition-delay: 0.15s;
	}
	&-label {
		display: flex;
		align-items: center;
		position: relative;
		z-index: 1;
		font-size: rem-calc(13);
		color: get-color(bodyLight);
		margin-right: $global-margin / 2;
		transition: $global-transition;
		transition-delay: 0.15s;
	}
	&-label-icon {
		position: relative;
		z-index: -1;
		width: rem-calc(8);
		height: rem-calc(8);
		border-radius: 100%;
		margin-right: rem-calc(8);
		transition: $global-transition;
		transition-delay: 0s;
		background: linear-gradient(90deg, var(--background-color),  var(--background-color-dark));
		background-size: 100% 100%;
	}
	&-date {
		position: relative;
		z-index: 1;
		font-size: rem-calc(13);
		color: get-color(bodyLight);
		margin-left: $global-margin;
		transition: $global-transition;
		transition-delay: 0.15s;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: rem-calc(-14);
			width: rem-calc(2);
			height: rem-calc(2);
			border-radius: 100%;
			background-color: get-color(bodyLight);
			transition: $global-transition;
			transition-delay: 0.15s;
		}
	}
	&-heading {
		position: relative;
		z-index: 1;
		@extend h4;
		color: get-color(darkGrey);
		margin-bottom: 0;
		max-width: rem-calc(300);
		transition: $global-transition;
		transition-delay: 0.15s;
	}
	&-content {
		display: block;
		position: relative;
		overflow: hidden;
		background-color: get-color(white);
		padding: $global-padding * 2;
		transition: $global-transition;
		transition-delay: 0.05s;
	}
	&-image {
		display: block;
		position: relative;
		transition: $global-transition;
		transition-delay: 0.1s;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	&.colour-light {
		margin-bottom: $global-margin * 2;
		.blog-item {
			&-content {
				background-color: get-color(white);
				padding: $global-padding * 1.5;
				padding-left: 0;
			}
			&-heading {
				color: get-color(darkGrey);
			}
			&-label {
				color: get-color(body);
			}
			&-date {
				color: get-color(body);
			}
		}
		&:hover {
			.blog-item {
				&-content {
					padding-left: $global-padding * 1.5;
					transform: translatey(0);
				}
				&-heading {
					color: get-color(white);
				}
			}
		}
	}
	&.image-height-auto {
		.blog-item {
			&-image {
				height: auto;
			}
		}
	}
	&:hover {
		.blog-item {
			&-image,
			&-content {
				transform: translatey(10px);
			}
			&-heading {
				color: get-color(white);
			}
			&-content {
				transition-delay: 0.05s;
			}
			&-image {
				transition-delay: 0s;
			}
			&-info {
				transform: translatex(-15px);
			}
			&-label {
				color: get-color(white);
			}
			&-date {
				color: get-color(white);
				&:before {
					background-color: get-color(white);
				}
			}
			&-label-icon {
				transform: scale(90);
				transition-delay: 0.25s;
			}
		}
	}
}
