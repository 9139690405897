.testimonial-items {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	&:before {
		content: normal;
	}
	&.type-single-row {
		@include breakpoint(medium down) {
			.testimonial-item:last-child {
			//	display: none;
			}
		}
	}
}


@media (max-width: 820px) {
	.testimonial-items .testimonial-item:last-child
	{
		display: none;
	}
  }


.testimonial-item {
	@extend .small-12;
	@extend .medium-6;
	@extend .large-4;
	padding: 0;
	position: relative;
	&-image {
		position: relative;
		z-index: 1;
		transition: $global-transition;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	&-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		background: linear-gradient(to bottom, rgba(0,0,0,0.0) 30%,rgba(0,0,0,1.0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
		transition: $global-transition;
	}
	&-content {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: $global-padding * 2;
		z-index: 3;
	}
	&-icon {
		display: block;
		position: relative;
		width: rem-calc(80);
		height: rem-calc(80);
		color: get-color(white);
		margin-bottom: $global-margin * 2;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: $global-transition;
		transition-delay: 0.15s;
		@include breakpoint(small down) {
			width: rem-calc(60);
			height: rem-calc(60);
			font-size: rem-calc(12);
		}
		span {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translatex(130%) translatey(-50%);
			font-size: rem-calc(11);
			font-weight: $global-weight-bold;
			text-transform: uppercase;
			color: get-color(white);
			opacity: 0.0;
			transition: $global-transition;
			transition-delay: 0.3s;
		}
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			border-radius: 100%;
			border: rem-calc(2) solid get-color(white);
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
		}
		&:after {
			content: '';
			width: 100%;
			height: 100%;
			border-radius: 100%;
			z-index: 2;
			position: absolute;
			top: 0;
			left: 0;
			transition: all 0.4s get-easing(in-out-circ);
			background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
			background-size: 200% 200%;
			animation: gradient 4s alternate infinite;
			clip-path: circle(0% at 20% -5%);
		}
		i {
			position: relative;
			z-index: 3;
			transition: $global-transition;
			transition-delay: 0.05s;
		}
	}
	&-heading {
		display: block;
		position: relative;
		font-size: rem-calc(16);
		line-height: rem-calc(24);
		font-weight: $global-weight-bold;
		color: get-color(white);
		margin-bottom: $global-margin;
		transition: $global-transition;
		transition-delay: 0.1s;
	}
	&-sub-heading {
		display: block;
		position: relative;
		font-size: rem-calc(14);
		color: get-color(white);
		transition: $global-transition;
		transition-delay: 0.15s;
		span {
			font-weight: $global-weight-bold;
		}
	}
	&:hover {
		.testimonial-item {
			&-image,
			&-overlay,
			&-heading,
			&-sub-heading {
				transform: translatey(10px);
			}
			&-icon {
				transform: scale(1.1);
				&:after {
					clip-path: circle(120% at 20% -5%);
				}
				i {
					transform: scale(0.8);
				}
				span {
					//opacity: 1.0;
					transform: translatex(140%) translatey(-50%);
				}
			}
		}
	}
}
