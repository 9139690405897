p {
	font-size: rem-calc(16);
	line-height: rem-calc(25);
	color: get-color(body);
	font-weight: $global-weight-light;
	@include breakpoint(small down) {
		font-size: rem-calc(15);
	}
}
.body-copy {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
	ul,
	ol {
		font-size: rem-calc(16);
		line-height: rem-calc(25);
		color: get-color(body);
		font-weight: $global-weight-light;
	}
	&.size-small {
		p {
			font-size: rem-calc(14);
			line-height: rem-calc(23);
		}
	}
	&.colour-bodyLight {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: get-color(bodyLight);
		}
		p {
			color: get-color(bodyLight);
		}
	}
	&.colour-white {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: get-color(white);
		}
		p {
			color: get-color(white);
		}
		a {
			color: get-color(white);
			text-decoration: underline;
			&:hover {
				color: get-color(white);
				opacity: 0.5;
			}
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	&.sans-serif-bold {
		font-family: $font-family-sans-serif;
		font-weight: $global-weight-bold;
	}
	&.sans-serif-normal {
		font-family: $font-family-sans-serif;
		font-weight: $global-weight-normal;
	}
}


.page .body-copy
{
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: $global-weight-normal;
	}
	p {
		font-size: rem-calc(15);
		}
	ul,
	ol {
		font-size: rem-calc(16);
		line-height: rem-calc(25);
		color: get-color(body);
		font-weight: $global-weight-light;
	}
}

// Dark Grey Background Colour
.page .colour-background-darkGrey {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	ul,
	ol,
	li,
	p {
		color: get-color(white);
	}
	.colour-primary {
		color: get-color(primary);
	}
	.colour-dark
	{
		color: get-color(darkGrey);
	}
	.colour-secondary
	{
		color:get-color(midGrey);
	}
}



.colour-gradient {
	background-image: gradient( linear, left top, right top, color-stop(0, get-color(primary)), color-stop(1, get-color(secondary)) );
	background-image: -webkit-gradient( linear, left top, right top, color-stop(0, get-color(primary)), color-stop(1, get-color(secondary)) );
	color:transparent;
	background-clip: text;
	-webkit-background-clip: text;
}
