$imageSlideshowHeight: rem-calc(700);

.image-slideshow {
	position: relative;
	&-overlay {
		width: 100%;
		height: 80%;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
		background: linear-gradient(to bottom, rgba(0,0,0,0.0) 30%,rgba(0,0,0,0.7) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
		pointer-events: none;
	}
	&-image {
		display: block;
		position: relative;
		z-index: 1;
		// height: rem-calc(700);
		// @include breakpoint(small down) {
		// 	height: rem-calc(300);
		// }
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	// Loader Height
	.slideshow {
		position: relative;
		z-index: 3;
		&-loader {
			height: rem-calc(700);
			@include breakpoint(small down) {
				height: rem-calc(300);
			}
		}
		&[data-slideshow-type='imageSlideshow'] {
			// height: rem-calc(700);
			// @include breakpoint(small down) {
			// 	height: rem-calc(300);
			// }
			&.owl-loaded {
				height: auto;
			}
		}
	}
	// Controls
	.owl-next,
	.owl-prev {
		opacity: 0.0;
		transform: scale(0.8);
		transition: $global-transition;
	}
	.owl-dot {
		opacity: 0.0;
		transform: scale(0.4);
		transition: $global-transition;
		@include breakpoint(small down) {
			opacity: 1.0;
			transform: scale(1);
		}
		@for $i from 1 through 20 {
			&:nth-child(#{$i}) {
				transition-delay: #{$i * 0.1}s;
			}
		}
	}
	&:hover {
		.owl-next,
		.owl-prev {
			opacity: 1.0;
			transform: scale(1);
		}
		.owl-dot {
			transform: scale(1);
			opacity: 1.0;
		}
	}
}
