.downloads-items {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
.downloads-item {
	@extend .medium-4;
	@extend .column;
	margin-bottom: $global-margin * 4;
	@include breakpoint(small down) {
		margin-bottom: $global-margin * 2;
	}
	&-heading {
		@extend h4;
		transition: $global-transition;
		transition-delay: 0.15s;
	}
	&-image {
		display: block;
		position: relative;
		height: rem-calc(300);
		margin-bottom: $global-margin;
		transition: $global-transition;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	&-image-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.9;
		transition: all 0.5s get-easing(in-out-circ);
		background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
		background-size: 200% 200%;
		animation: gradient 6s alternate infinite;
	}
	&-image-button {
		position: absolute;
		z-index: 3;
		bottom: $global-position;
		left: $global-position ;
		font-size: rem-calc(11);
		font-weight: $global-weight-bold;
		text-transform: uppercase;
		opacity: 0.0;
		transform: translatex(-10px);
		transition: $global-transition;
		transition-delay: 0s;
		color: get-color(white);
	}
	&:hover {
		.downloads-item {
			&-image,
			&-heading,
			&-label {
				transform: translatey(10px);
			}
			&-label-icon {
				transform: scale(1.3);
			}
			&-image-button {
				opacity: 1.0;
				transform: translatex(0px);
				transition-delay: 0.25s;
			}
		}
	}
	// Clip Positions
	&:nth-child(5n + 1) {
		.downloads-item-image-overlay {
			clip-path: circle(0% at 50% -10%);
		}
		&:hover {
			.downloads-item-image-overlay {
				clip-path: circle(140% at 50% -10%);
			}
		}
	}
	&:nth-child(5n + 2) {
		.downloads-item-image-overlay {
			clip-path: circle(0% at -20% -10%);
		}
		&:hover {
			.downloads-item-image-overlay {
				clip-path: circle(170% at -20% -10%);
			}
		}
	}
	&:nth-child(5n + 3) {
		.downloads-item-image-overlay {
			clip-path: circle(0% at 120% -20%);
		}
		&:hover {
			.downloads-item-image-overlay {
				clip-path: circle(180% at 120% -20%);
			}
		}
	}
	&:nth-child(5n + 4) {
		.downloads-item-image-overlay {
			clip-path: circle(0% at 100% 120%);
		}
		&:hover {
			.downloads-item-image-overlay {
				clip-path: circle(160% at 100% 120%);
			}
		}
	}
	&:nth-child(5n + 5) {
		.downloads-item-image-overlay {
			clip-path: circle(0% at 50% 120%);
		}
		&:hover {
			.downloads-item-image-overlay {
				clip-path: circle(150% at 50% 120%);
			}
		}
	}
}
