.category-nav {
	display: flex;
	position: relative;
	flex-wrap: wrap;
	max-width: 100%;
	@include breakpoint(small down) {
		display: none;
	}
	&-item {
		margin-right: $global-margin * 2.5;
		margin-bottom: $global-margin;
		display: flex;
		align-items: center;
		@include breakpoint(small down) {
			margin-right: 0;
			margin-bottom: $global-margin / 2;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&-icon {
			position: relative;
			z-index: -1;
			width: rem-calc(8);
			height: rem-calc(8);
			border-radius: 100%;
			margin-right: rem-calc(8);
			transition: $global-transition;
			transition-delay: 0s;
			background: linear-gradient(90deg, var(--background-color),  var(--background-color-dark));
			background-size: 100% 100%;
		}
		&-label {
			display: inline-block;
			position: relative;
			font-size: rem-calc(13);
			color: get-color(bodyLight);
			transition: $global-transition;
			transition-delay: 0.15s;
		}
		&:hover {
			.category-nav-item {
				&-icon {
					transform: scale(1.4);
				}
				&-label {
					transform: translatex(3px);
					color: get-color(body);
				}
			}
		}
		&.is-active {
			.category-nav-item {
				&-icon {
					transform: scale(1.8);
				}
				&-label {
					transform: translatex(3px);
					color: get-color(darkGrey);
				}
			}
		}
	}
}
