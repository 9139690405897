.section {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	position: relative;
	&-container {
		position: relative;
		width: 100%;
		height: 100%;
		max-width: $global-width;
		padding: {
			left: 7vw;
			right: 7vw;
		};
		@media (min-width: $global-width) {
			margin: {
				left: auto;
				right: auto;
			}
			padding: {
				left: 0vw;
				right: 0vw;
			}
		}
	}
	&-background-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		transition: $global-transition;
		opacity: 0.7;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	&-map {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		transition: $global-transition;
		opacity: 0.3;
		@include breakpoint(small down) {
			opacity: 0.0;
		}
		&:before {
			content: '';
			width: 50vw;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			background-color: get-color(black);
			opacity: 0.7;
			z-index: 2;
			pointer-events: none;
		}
	}
	&-map-item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&-gradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		//clip-path: circle(0% at -30% 50%);
		transition: $global-transition;
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 1;
			background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
			background-size: 300% 300%;
			animation: gradient 10s alternate infinite;
			transition: $global-transition;
		}
		&.is-active {
			//clip-path: circle(150% at -0% 50%);
			&:before {

			}
		}
	}
}
