@keyframes zoomIn {
	0% {
		transform: scale(1.0);
	}
	100% {
		transform: scale(1.2);
	}
}
@keyframes zoomOut {
	0% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1.0);
	}
}
@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}