.blog-info {
	display: flex;
	align-items: center;
	margin-bottom: $global-margin / 2;
	@include breakpoint(small down) {
		margin-bottom: $global-margin * 1.5;
	}
	&-image {
		width: rem-calc(50);
		height: rem-calc(50);
		border-radius: 100%;
		overflow: hidden;
		margin-right: $global-margin;
		img {
			object-fit: cover;
			object-position: center center;
			width: 100%;
			height: 100%;
		}
	}
	&-text {

	}
}
