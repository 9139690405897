:root {
	.picker {
		outline: none;
		&--opened {
			.picker {
				&__holder {
					background-color: rgba(get-color(darkGrey), 0.8);
				}
				&__frame {
					margin-bottom: 0;
					bottom: 50%;
					transform: translatey(50%);
				}
			}
		}
		&--focused {
			.picker {
				&__day {
					&--focused,
					&--selected,
					&--highlighted {
						z-index: 10;
						border: none;
						background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
						background-size: 200% 200%;
						color: get-color(white);
					}
				}
			}
		}
		&__frame {
			max-width: rem-calc(450);
			margin-bottom: 0;
			bottom: 50%;
			transform: translatey(50%);
		}
		&__month {
			font-size: rem-calc(16);
			font-family: $font-family-sans-serif;
		}
		&__year {
			font-size: rem-calc(13);
			font-family: $font-family-sans-serif;
		}
		&__weekday,
		&__day {
			font-size: rem-calc(15);
			font-family: $font-family-sans-serif;
			padding: $global-padding / 2 0;
		}
		&__day {
			transition: $global-transition;
			&:hover {
				background-color: get-neutral-color(95%);
			}
			&--today {
				&:before {
					border-top-color: get-color(secondary);
				}
			}
			&--focused,
			&--selected,
			&--highlighted {
				z-index: 10;
				border: none;
				background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
				background-size: 200% 200%;
				color: get-color(white);
				animation: gradient 8s alternate infinite;
			}
		}
		&__box {
			border-color: transparent;
		}
		&__holder,
		&__frame {
			outline: none;
		}
		&__nav--prev,
		&__nav--next {
			transition: $global-transition;
			&:hover {
				background-color: get-neutral-color(95%);
			}
			&:before {
				transform: scale(0.4);
			}
		}
		button {
			font-size: rem-calc(12);
			color: get-color(darkGrey);
			font-family: $font-family-sans-serif;
			padding-top: $global-padding / 1.5;
			background-color: transparent;
			transition: $global-transition;
			&:hover {
				background-color: get-neutral-color(95%);
			}
		}
		&__button {
			&--today {
				&:before {
					border-top-color: get-color(secondary);
				}
			}
		}
	}
}