// ===============================
//
// Mixins
//
// ===============================

	//
	// Easing
	//

		$easing: (
			in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530),
			in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190),
			in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220),
			in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060),
			in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715),
			in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035),
			in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335),
			in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045),
			out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940),
			out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000),
			out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000),
			out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000),
			out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000),
			out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000),
			out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000),
			out-back: cubic-bezier(0.175, 0.885, 0.320, 1.275),
			in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955),
			in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000),
			in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000),
			in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000),
			in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950),
			in-out-expo: cubic-bezier(1.000,  0.000, 0.000, 1.000),
			in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860),
			in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550)
		);
		
		@function get-easing($key) {
			@if map-has-key($easing, $key) {
				@return map-get($easing, $key);
			}
		}
		
	//
	// Font Face
	//
	
		@mixin font-face($family, $file, $weight, $style) {
			
			@font-face {
			    font-family: '#{$family}';
			    src: url('#{$font-path}/#{$file}.eot');
			    src: url('#{$font-path}/#{$file}.eot?#iefix') format('embedded-opentype'),
			        url('#{$font-path}/#{$file}.woff2') format('woff2'),
			        url('#{$font-path}/#{$file}.woff') format('woff'),
			        url('#{$font-path}/#{$file}.ttf') format('truetype'),
			        url('#{$font-path}/#{$file}.svg##{$file}') format('svg');
			    font-weight: $weight;
			    font-style: $style;
			}
			
		}

	//
	// Gutter
	//
	
		@mixin gutter($sides, $property: margin, $negative: false) {
			@each $breakpoint, $gutter in $grid-column-gutter {
				@include breakpoint($breakpoint) {
					@each $side in $sides {
						$size: $gutter;
						@if ($side == left or $side == right) {
							$size: $gutter / 2;
						}
						@if ($negative == true) {
							$size: -($size);
						}
						#{$property}-#{$side}: rem-calc($size);
					}
				}
			}
		}
	
	//
	// SVG
	//
	
		@mixin svg($property, $value) {
			svg,
			path,
			polygon,
			g,
			circle,
			polyline,
			rect,
			line,
			text {
				#{$property}: $value;
			}
		}
	
	//
	// SVG Fill
	//

		@mixin svg-fill($color) {
			@include svg(fill, $color);
		}
		
	//
	// Icon
	//
	
		@mixin icon-sizes($increment: 4, $total: 20){
			
			@for $i from 1 through $total {
	
				$increment-size: $increment * $i;
				$increment-size-rem: rem-calc($increment-size);
		
				.icon {
					&.type-size-#{$increment-size} {
						width: $increment-size-rem;
						height: $increment-size-rem;
					}
				}
	
			}
			
		}
		
	//
	// Map Reverse
	//
	
		@function map-reverse ($map) {
		    $result: null;
		    @if type-of($map) == "map" {
		        $keys: map-keys($map);
		        $map-reversed: ();
		        @for $i from length($keys) through 1 {
		            $map-reversed: map-merge(
		                $map-reversed,
		                (nth($keys, $i): map-get($map, nth($keys, $i)))
		            );
		        }
		        @if type-of($map-reversed) == "map" {
		            $result: $map-reversed;
		        }
		    }
		    @return $result;
		}

	//
	// Neutral Color
	//
		
		@function get-neutral-color($percent) {
			@if map-has-key($foundation-palette, neutral) {
				@return lighten(map-get($foundation-palette, neutral), $percent);
			}
		}

	//
	// Button Color
	//
	
		@mixin button-color($background-color, $color) {
			color: $color;
			background-color: $background-color;
			&:hover,
			&:active,
			&:focus {
				color: $color;
			}
			&:hover,
			&:active {
				background-color: darken($background-color, 10%);
			}
			&:focus {
				background-color: darken($background-color, 20%);
				box-shadow: 0 0 0 rem-calc(2) rgba($background-color, 0.5);
			}
		}
		
	//
	// Gradient
	//

		// Horizontal
		
			@mixin gradient-horizontal($start-color: get-color(primary), $end-color: get-color(secondary), $start-percent: 0%, $end-percent: 100%) {
				background-color: $start-color;
				background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
				background-repeat: repeat-x;
			}
		
		// Vertical
		
			@mixin gradient-vertical($start-color: get-color(primary), $end-color: get-color(secondary), $start-percent: 0%, $end-percent: 100%) {
				background-color: $start-color;
				background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
				background-repeat: repeat-x;
			}
		
		// Radial
		
			@mixin gradient-radial($inner-color: get-color(primary), $outer-color: get-color(secondary)) {
				background-color: $inner-color;
				background-image: radial-gradient(circle, $inner-color, $outer-color);
				background-repeat: no-repeat;
			}
			