.slideshow-container {
	position: relative;
	overflow: hidden;
	display: block;
	width: 100%;
	height: auto;
}


.slideshow-work-items-container {
	position: relative;
	overflow: hidden;
	display: block;
	width: 600px;
	height: auto;
}

.slideshow {
	display: block;
	position: relative;
	opacity: 0.0;
	transform: translatey(10px);
	transition: $global-transition;
	transition-delay: 1s;
	&.owl-loaded {
		height: auto;
		opacity: 1.0;
		transform: translatey(0px);
	}
	&-loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 5;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: $global-transition;
		opacity: 0.0;
		pointer-events: none;
		i {
			animation: rotate 1.4s get-easing(in-out-circ) infinite;
			@include breakpoint(small down) {
				position: relative;
				top: rem-calc(20);
			}
		}
		&.is-active {
			opacity: 1.0;
		}
	}
	// Custom Pagination
	&-pagination {
		display: block;
		position: relative;
		ul {
			list-style: none;
			display: block;
			position: relative;
			margin: 0;
			padding: 0;
		}
		li {
			@extend .owl-dot;
		}
	}
}
// Controls
.owl-carousel {
	.owl-nav {
		width: 100%;
		position: absolute;
		top: 50%;
		transform: translatey(-50%);
		z-index: 10;
		transition: $global-transition;
		@include breakpoint(small down) {
			display: none;
		}
		button.owl-prev,
		button.owl-next {
			display: block;
			position: absolute;
			top: 50%;
			left: rem-calc(40);
			width: rem-calc(47);
			height: rem-calc(47);
			border-radius: 100%;
			@include breakpoint(small down) {
				left: rem-calc(25);
			}
			i {
				position: relative;
				z-index: 3;
				color: get-color(white);
				font-size: rem-calc(15);
			}
			&:before {
				content: '';
				width: 100%;
				height: 100%;
				border-radius: 100%;
				border: rem-calc(2) solid rgba(get-color(white), 0.3);
				z-index: 1;
				position: absolute;
				top: 0;
				left: 0;
			}
			&:after {
				content: '';
				width: 100%;
				height: 100%;
				border-radius: 100%;
				z-index: 2;
				position: absolute;
				top: 0;
				left: 0;
				transition: all 0.4s get-easing(in-out-circ);
				background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
				background-size: 200% 200%;
				animation: gradient 4s alternate infinite;
				clip-path: circle(0% at 20% -5%);
			}
			&:hover,
			&:focus,
			&:active {
				box-shadow: none;
			}
			&:hover {
				transform: scale(0.9);
				&:after {
					clip-path: circle(120% at 20% -5%);
				}
			}
		}
		button.owl-next {
			left: auto;
			right: rem-calc(40);
			@include breakpoint(small down) {
				right: rem-calc(25);
				left: auto;
			}
			i {
				top: rem-calc(-1);
				right: rem-calc(-1);
			}
		}
		button.owl-prev {
			i {
				top: rem-calc(-1);
				left: rem-calc(-1);
			}
		}
	}
}
.owl-dots {
	position: absolute;
	bottom: rem-calc(40);
	left: 50%;
	transform: translatex(-50%);
	z-index: 10;
	@include breakpoint(small down) {
		bottom: rem-calc(20);
	}
}
.owl-dot {
	display: inline-block;
	position: relative;
	width: rem-calc(30);
	height: rem-calc(30);
	cursor: pointer;
	border: none;
	&:after {
		display: none;
	}
	&:hover,
	&:focus,
	&:active {
		box-shadow: none;
		transform: translate(0);
		&:before {
			transform: translate(-50%, -50%) scale(0.6);
		}
	}
	&.is-active,
	&.active {
		&:before {
			transform: translate(-50%, -50%) scale(1.8);
			opacity: 1.0;
		}
	}
	&:before {
		content: '';
		width: rem-calc(8);
		height: rem-calc(8);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 100%;
		background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
		background-size: 300% 300%;
		animation: gradient 7s alternate infinite;
		opacity: 0.3;
		transition: $global-transition;
		border: none;
	}
}
