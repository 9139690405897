.logo-items {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	&:before {
		content: normal;
	}
}
.logo-item {
	cursor: pointer;
	@extend .small-6;
	@extend .medium-4;
	@extend .large-4;
	@extend .xlarge-3;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	height: rem-calc(180);
	margin: {
		bottom: $global-margin;
	};
	transition: $global-transition;
	@include breakpoint(large down) {
		height: rem-calc(140);
	}
	@include breakpoint(small down) {
		height: rem-calc(100);
	}
	svg {
		max-width: rem-calc(120);
		position: relative;
		z-index: 2;
		width: 100%;
		height: 100%;
		transition: $global-transition;
		fill: rgba(get-color(white), 1);
		@include breakpoint(small down) {
			max-width: rem-calc(90);
		}
	}
	&:nth-child(even) {
		&:before {
			background: linear-gradient(90deg, get-color(primary), get-color(tertiary), get-color(secondary));
			background-size: 200% 200%;
		}
	}
	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
		background-size: 200% 200%;
		animation: gradient 6s alternate infinite;
		transition: all 0.4s get-easing(in-out-circ);
	}
	&:hover {
		transform: scale(0.9);
		svg {
			transform: scale(0.95);
			fill: get-color(white);
		}
		&:before {
			opacity: 1.0;
		}
	}
	// Clip Positions
	&:nth-child(5n + 1) {
		&:before {
			clip-path: circle(0% at 50% -20%);
		}
		&:hover {
			&:before {
				clip-path: circle(115% at 50% -20%);
			}
		}
	}
	&:nth-child(5n + 2) {
		&:before {
			clip-path: circle(0% at -20% -20%);
		}
		&:hover {
			&:before {
				clip-path: circle(170% at -20% -20%);
			}
		}
	}
	&:nth-child(5n + 3) {
		&:before {
			clip-path: circle(0% at 120% -20%);
		}
		&:hover {
			&:before {
				clip-path: circle(170% at 120% -20%);
			}
		}
	}
	&:nth-child(5n + 4) {
		&:before {
			clip-path: circle(0% at 100% 120%);
		}
		&:hover {
			&:before {
				clip-path: circle(155% at 100% 120%);
			}
		}
	}
	&:nth-child(5n + 5) {
		&:before {
			clip-path: circle(0% at 50% 120%);
		}
		&:hover {
			&:before {
				clip-path: circle(115% at 50% 120%);
			}
		}
	}
}

.remove-logo-hover:before
{
	width: 0%;
	height: 0%;
}

.remove-logo-hover:hover {
	transform: scale(1);
	opacity: 0.6;
	svg {
		transform: scale(1);
		fill: get-color(white);
	}

	&:before {
		opacity: 0;
	}
}