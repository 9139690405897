.hero-slideshow {
	display: block;
	position: relative;
	width: 100%;
	height: 100vh;
	background-color: get-color(darkGrey);
	overflow: hidden;
	&-slide {
		display: block;
		position: relative;
		width: 100%;
		height: 100vh;
	}
	&-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		background-color: rgba(get-color(darkGrey), 0.3);
	}
	&-image {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	&-content {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translatey(-50%);
		z-index: 3;
	}
	&-heading {
		
	}
	&-button {
		
	}
}
// Transitions / Animations
.slideshow[data-slideshow-type="heroSlideshow"] {
	.hero-slideshow-image {
		img {
			animation: zoomIn 20s linear alternate infinite;	
		}
	}
	.owl-item {
		.hero-slideshow {
			&-heading {
				transition: $global-transition;
				transition-delay: 0.3s;
				opacity: 0.0;
				transform: translatex(-20px);
			}
			&-button {
				transition: $global-transition;
				transition-delay: 0.35s;
				opacity: 0.0;
				transform: translatex(-20px);
				.button.type-text {

				}
			}
			&-image {
				transition: $global-transition;
				opacity: 0.0;
			}
		}
	}
	.owl-item.active {
		.hero-slideshow {
			&-heading {
				opacity: 1.0;
				transform: translatex(0);
			}
			&-button {
				opacity: 1.0;
				transform: translatex(0);
			}
			&-image {
				opacity: 1.0;
			}
		}
	}
}

.hero-slideshow-video
{
	width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.hero-slideshow-video video
{
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;
}