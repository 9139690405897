.profile-image {
	display: block;
	position: relative;
	overflow: hidden;
	width: rem-calc(70);
	height: rem-calc(70);
	border-radius: 100%;
	margin: auto;
	margin-bottom: $global-margin;
	img {
		object-fit: cover;
		object-position: center center;
		width: 100%;
		height: 100%;
	}
}

.social-profile i
{
	color:#1C252C;
}

.social-profile i:hover
{
	color:#FFB711;
}