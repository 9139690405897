

.gif-hack-lg
{
	width: 1200px;
    height: 600px;
    object-fit: cover;
}

.gif-hack-square
{
    object-fit: cover;
}


.image-gallery {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	&:before {
		content: none;
	}
	&-row {
		display: flex;
		margin-bottom: rem-calc(50);
		@include breakpoint(large down) {
			margin-bottom: rem-calc(20);
		}
		@include breakpoint(small down) {
			flex-wrap: wrap;
			margin-bottom: rem-calc(0);
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	&-column {
		flex: 1;
		margin-right: rem-calc(50);
		@include breakpoint(large down) {
			margin-right: rem-calc(20);
		}
		@include breakpoint(small down) {
			margin-right: rem-calc(0);
			margin-bottom: rem-calc(10);
			flex: none;
			width: 100%;
			display: block;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
