.site-menu {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 4;
   background-color: $black;
   display: flex;
   align-items: center;
   justify-content: center;
   opacity: 0;
   visibility: hidden;
   transition: all 0.6s get-easing(in-out-circ);
	display: none;
   @include breakpoint(medium down) {
		display: flex;
      justify-content: flex-start;
   }
	&-action {
		display: none;
		color: get-color(white);
		&.type-light {
			color: get-color(black);
			&:hover,
			&:focus,
			&:active {
				color: get-color(black);
			}
		}
		&:hover,
		&:focus,
		&:active {
			color: get-color(white);
		}

		@include breakpoint(medium down) {
			display: block;
		}
	}
   &.is-active {
      visibility: visible;
      opacity: 1;
      .site-menu {
         &-nav {
            a {
               $elements: 15;
               @for $i from 0 to $elements {
                 &:nth-child(#{$i + 1}) {
                     transition-delay: $i * 0.1s;
                 }
               }
               opacity: 1.0;
               &:hover {
                  opacity: 0.5;
               }
            }
         }
      }
   }
   &-nav {
      display: flex;
      flex-direction: column;
		justify-content: center;
		margin: auto;
		text-align: center;
      a {
			&:not(.button) {
				font-size: rem-calc(18);
            font-weight: $global-weight-bold;
	         color: get-color(white);
			}
			&.button {
				margin-top: $global-margin * 2;
			}
         transition-delay: 0;
         opacity: 0.0;
			margin-bottom: $global-margin;
         &:hover {
            opacity: 0.5;
         }
      }
   }
}


.times {
   display: none;
}
.is-active {
      .times {
         display: block;
      }
      .bars {
         display: none;
      }
}