.video-link {
   position: relative;
   display: block;
   overflow: hidden;
   &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: rem-calc(100);
      height: rem-calc(100);
      border-radius: 100%;
      border: rem-calc(1) solid rgba(get-color(white), 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      color: get-color(white);
      font-size: rem-calc(14);
      z-index: 3;
      transition: $global-transition;
   }
   &-image {
      transition: $global-transition;
      position: relative;
      z-index: 2;
   }
   &:hover {
      .video-link-icon {
         transform: translate(-50%, -50%) scale(0.95);
      }
      .video-link-image {
         transform: scale(1.1);
      }
   }
}


@media screen and (max-width: 39.9375em) {
   .work-video
   {
      height:300px !important;
   }
}

