.site-header {
	width: 100%;
	display: flex;
	position: relative;
	z-index: 11;
	justify-content: space-between;
	align-items: center;
	padding: $global-padding * 2 $global-padding * 3;
	padding-bottom: 0;
	&.type-light {
		.site-header {
			&-logo {
				color: get-color(white);
				&:hover,
				&:focus,
				&:active {
					color: get-color(white);
					opacity: 0.6;
				}
			}
			&-nav {
				a {
					.link-text {
						span {
							color: get-color(white);
						}
					}
				}
				li {
					&.type-dropdown {
						a {
							&:before {
								color: get-color(white);
							}
						}
					}
				}
			}
		}
	}
	&-group {
		display: flex;
		align-items: center;
	}
	&-logo {
		display: inline-block;
		position: relative;
		font-family: $font-family-slab-serif;
		font-size: rem-calc(35);
		color: get-color(darkGrey);
		transform: translatey(-4px);
		@include breakpoint(large down) {
			font-size: rem-calc(30);
		}
		@include breakpoint(small down) {
			font-size: rem-calc(25);
		}
		&:hover,
		&:focus,
		&:active {
			color: get-color(darkGrey);
			opacity: 0.6;
			transform: translatey(-6px);
		}
	}
	&-logo-wrap {
		flex-grow: 1;
	}
	&-nav {
		display: inline-block;
		position: relative;
		@include breakpoint(medium down) {
			display: none;
		}
		ul {
			@extend .list-blank;
			margin: 0;
			padding: 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
		}
		li {
			position: relative;
			padding-top: $global-padding;
			padding-bottom: $global-padding / 2;
			margin: {
				left: $global-margin;
				right: $global-margin;
			}
			@include breakpoint(large down) {
				margin: {
					left: $global-margin / 1.2;
					right: $global-margin / 1.2;
				}
			}
			&.type-dropdown {
				a {
					padding-right: $global-padding;
					&:before {
						@extend .fas;
						content: "\f0d7";
						font-family: $font-family-icon;
						position: absolute;
						color: get-color(black);
						top: rem-calc(2);
						right: 0;
						pointer-events: none;
						font-size: rem-calc(10);
						transition: $global-transition;
					}
				}
				&.is-active {
					a {
						transform: translatey(-4px);
						.link-text {
							span:first-child {
								transform: translatey(-105%);
							}
							span:last-child {
								transform: translatey(0%);
							}
						}
						.button-text {
							transform: translatey(-3px);
						}
						&:after {
							transform: translatey(0px);
							opacity: 1.0;
						}
						&:after {
							background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
							background-size: 200% 200%;
							animation: gradient 4s alternate infinite;
						}
						&:before {
							transform: rotate(180deg);
							top: rem-calc(3);
						}
					}
				}
			}
			&.is-active {
				a {
					&:after {
						background: linear-gradient(90deg, get-color(white), get-color(white));
						height: rem-calc(2);
						transform: translate(0);
						opacity: 1.0;
					}
				}
			}
		}
		a {
			display: inline-block;
			position: relative;
			padding: 0;
			background-color: transparent;
			overflow: hidden;
			padding-bottom: rem-calc(7);
			border-radius: 0;
			transform: translatey(-2px);
			.link-text {
				position: relative;
				display: block;
				overflow: hidden;
				z-index: 3;
				transition: $global-transition;
				span {
					display: block;
					transition: $global-transition;
					transition-delay: 0.1s;
					width: 100%;
					text-align: center;
					font-size: rem-calc(14);
					color: get-color(darkGrey);
				}
				span:first-child {
					position: relative;
				}
				span:last-child {
					top: 0;
					left: 0;
					position: absolute;
					transform: translatey(105%);
				}
			}
			span {
				text-transform: none;
				font-size: rem-calc(14);
				font-weight: $global-weight-normal;
			}
			&:before {
				display: none;
			}
			&:after {
				content: '';
				width: 100%;
				height: rem-calc(2);
				position: absolute;
				border-radius: 0;
				top: auto;
				bottom: 0;
				left: 0;
				clip-path: none;
				background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
				background-size: 200% 200%;
				animation: gradient 4s alternate infinite;
				transform: translatey(1px);
				transition: $global-transition;
				transition-delay: 0.1s;
				opacity: 0.0;
			}
			&:hover,
			&:focus,
			&:active {
				transform: translatey(-4px);
				.link-text {
					span:first-child {
						transform: translatey(-105%);
					}
					span:last-child {
						transform: translatey(0%);
					}
				}
				.button-text {
					transform: translatey(-3px);
				}
				&:after {
					transform: translatey(0px);
					opacity: 1.0;
				}
				box-shadow: none;
			}
		}
	}
	&-button {
		display: inline-block;
		position: relative;
		@include breakpoint(medium down) {
			display: none;
		}
	}
	&-nav-dropdown {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding-top: $global-padding * 10;
		padding-bottom: $global-padding * 4;
		background-color: rgba(get-color(darkGrey), 0.9);
		z-index: 10;
		transition: $global-transition;
		pointer-events: none;
		opacity: 0.0;
		overflow: hidden;
		transform: translatey(-10px);
		&.is-active {
			pointer-events: all;
			opacity: 1.0;
		transform: translatey(0px);
		}
		&-row {
			width: 100%;
			display: flex;
			justify-content: center;
		}
		&-column {
			flex: 1;
		}
		&-heading {
			font-weight: $global-weight-bold;
			color: get-color(white);
			margin-bottom: $global-margin;
		}
		&-links {
			display: flex;
			flex-direction: column;
			a {
				display: inline-block;
				font-size: rem-calc(14);
				line-height: rem-calc(24);
				color: get-color(bodyLight);
				&:hover {
					color: get-color(white);
					transform: translatex(3px);
				}
			}
		}
	}
}
.site-header.type-fixed {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 12;
}
