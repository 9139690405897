.work-items {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	&:before {
		content: normal;
	}
}


@media (max-width: 820px) {
	.work-items .work-item:last-child
	{
		display: none;
	}
  }


.work-item {
	@extend .medium-6;
	@extend .large-4;
	@extend .xlarge-4;
	padding: 0;
	margin-bottom: $global-margin * 4;
	transition: all 0.5s get-easing(in-out-circ);
	opacity: 1.0;
	transform: translatey(0px);
	@include breakpoint(large down) {
		margin-bottom: $global-margin * 2;
	}
	@include breakpoint(small down) {
		margin-bottom: $global-margin * 2;
	}
	&.is-loading {
		opacity: 0.0;
		transform: translatey(3px);
	}
	&-label {
		display: flex;
		align-items: center;
		font-size: rem-calc(13);
		color: get-color(body);
		margin-bottom: $global-margin / 4;
		transition: $global-transition;
		transition-delay: 0.05s;
		span {
			margin-right: $global-margin;
		}
	}
	&-label-icon {
		width: rem-calc(8);
		height: rem-calc(8);
		border-radius: 100%;
		margin-right: rem-calc(5);
		transition: $global-transition;
		transition-delay: 0.1s;
		transform: translatey(#{rem-calc(-1)});
		background-color: var(--background-color);
	}
	&-heading {
		@extend h3;
		transition: $global-transition;
		transition-delay: 0.15s;
	}
	&-image {
		display: block;
		position: relative;
		margin-bottom: $global-margin;
		transition: $global-transition;
		overflow: hidden;
		//border: rem-calc(1) solid rgba(get-color(black), 0.05);
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	&-image-button {
		position: absolute;
		z-index: 3;
		bottom: $global-position;
		left: $global-position ;
		font-size: rem-calc(11);
		font-weight: $global-weight-bold;
		text-transform: uppercase;
		opacity: 0.0;
		transform: translatex(-10px);
		transition: $global-transition;
		transition-delay: 0s;
		color: get-color(white);
	}
	&-image-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.9;
		transition: all 0.5s get-easing(in-out-circ);
		background: linear-gradient(90deg, var(--background-color),  var(--background-color-dark));
		background-size: 200% 200%;
		animation: gradient 6s alternate infinite;
	}
	&:hover {
		.work-item {
			&-image,
			&-heading,
			&-label {
				transform: translatey(10px);
			}
			&-label-icon {
			//	transform: scale(1.3);
			}
			&-image-button {
				opacity: 1.0;
				transform: translatex(0px);
				transition-delay: 0.25s;
			}
		}
	}
	// Clip Positions
	&:nth-child(5n + 1) {
		.work-item-image-overlay {
			clip-path: circle(0% at 50% -10%);
		}
		&:hover {
			.work-item-image-overlay {
				clip-path: circle(140% at 50% -10%);
			}
		}
	}
	&:nth-child(5n + 2) {
		.work-item-image-overlay {
			clip-path: circle(0% at -20% -10%);
		}
		&:hover {
			.work-item-image-overlay {
				clip-path: circle(170% at -20% -10%);
			}
		}
	}
	&:nth-child(5n + 3) {
		.work-item-image-overlay {
			clip-path: circle(0% at 120% -20%);
		}
		&:hover {
			.work-item-image-overlay {
				clip-path: circle(180% at 120% -20%);
			}
		}
	}
	&:nth-child(5n + 4) {
		.work-item-image-overlay {
			clip-path: circle(0% at 100% 120%);
		}
		&:hover {
			.work-item-image-overlay {
				clip-path: circle(160% at 100% 120%);
			}
		}
	}
	&:nth-child(5n + 5) {
		.work-item-image-overlay {
			clip-path: circle(0% at 50% 120%);
		}
		&:hover {
			.work-item-image-overlay {
				clip-path: circle(150% at 50% 120%);
			}
		}
	}
}


.colour-background-darkGrey .work-item
{
	&-heading
	{
		color:get-color(white);
	}
}