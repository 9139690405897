.feature-links {
	display: block;
	position: relative;
	width: 100%;
	&-hover-image {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		overflow: hidden;
		@include breakpoint(small down) {
			display: none;
		}
		&-item {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			transition: $global-transition;
			opacity: 0.0;
			&.is-active {
				opacity: 1.0;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
			}
			&:nth-child(odd) {
				img {
					animation: zoomIn 20s linear alternate infinite;
				}
			}
			&:nth-child(even) {
				img {
					animation: zoomOut 20s linear alternate infinite;
				}
			}
		}
	}
	&-items {
		position: relative;
		z-index: 2;
		display: flex;
		@include breakpoint(large down) {
			flex-wrap: wrap;
			overflow: hidden;
			&:before {
				content: normal;
			}
			.feature-links {
				&-item {
					 flex: none;
					 width: 50%;

				}
			}
		}
		@include breakpoint(small down) {
			.feature-links {
				&-item {
					 flex: none;
					 width: 100%;

				}
			}
		}
		&.type-grid {
			flex-wrap: wrap;
			overflow: hidden;
			&:before {
				content: normal;
			}
			.feature-links {
				&-item {
					 flex: none;
					 width: 25%;					 
					@include breakpoint(large down) {
					  width: 50%;
				  }
					 @include breakpoint(small down) {
						 width: 100%;
					 }
				}
			}
		}
	}
	&-item {
		flex: 1;
		position: relative;
		height: rem-calc(520);
		@include breakpoint(large down) {
			height: rem-calc(350);
		}
		@include breakpoint(small down) {
			height: rem-calc(220);
			flex: none;
			width: 100%;
		}
		&.size-small {
			height: rem-calc(300);
			@include breakpoint(small down) {
				height: rem-calc(150);
			}
			.feature-links-item {
				&-content {
					padding: $global-padding * 2;
					@include breakpoint(small down) {
						padding: $global-padding * 1.5;
					}
				}
				&-label {
					top: $global-padding * 2;
					left: $global-padding * 2;
					@include breakpoint(small down) {
						top: $global-padding * 1.5;
						left: $global-padding * 1.5;
					}
				}
			}
		}
		&-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: $global-transition;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
			}
		}
		&-overlay {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			background-color: get-color(darkGrey);
			opacity: 0.0;
			transition: $global-transition;
		}
		&-content {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			z-index: 3;
			padding-left: $global-padding * 2;
			padding-bottom: $global-padding * 2;
			padding-right: $global-padding * 2;
			transition: $global-transition;
			@include breakpoint(small down) {
				padding: $global-padding * 1.5;
			}
		}
		&-label {
			position: absolute;
			top: $global-padding * 2;
			left: $global-padding * 2;
			color: get-color(white);
			font-size: rem-calc(14);
			z-index: 4;
			transition: $global-transition;
			@include breakpoint(small down) {
				top: $global-padding * 1.5;
				left: $global-padding * 1.5;
			}
		}
		&-heading {
			@extend h4;
			color: get-color(white);
			margin-bottom: 0;
			transition: $global-transition;
			transition-delay: 0.4s;
		}
		&-text {
			overflow: hidden;
			font-size: rem-calc(13);
			line-height: rem-calc(18);
			color: get-color(white);
			max-width: rem-calc(200);
			transition-delay: 0.3s;
			transition: all 0.7s get-easing(in-out-circ);
			@include breakpoint(small down) {
				display: none;
			}
			span {
				display: block;
				opacity: 0.0;
				transform: translatey(10px);
				transition: $global-transition;
				transition-delay: 0.2s;
				margin-bottom: $global-margin * 1.5;
			}
			.button {
				opacity: 0.0;
				transition: $global-transition;
				transition-delay: 0s;
			}
		}
		&.is-active {
			.feature-links-item {
				&-overlay {
					opacity: 0.6;
					background-color: get-color(black);
				}
				&-image {
					opacity: 0.0;
					@include breakpoint(small down) {
						opacity: 1.0;
					}
				}
				&-text {
					max-height: rem-calc(500);
					span,
					.button {
						opacity: 1.0;
						transform: translatey(0px);
					}
					.button {
						transition-delay: 0.4s;
					}
				}
				&-heading {
					transform: translatey(-10px);
					transition-delay: 0.1s;
				}
				&-label {
					transform: translatey(-10px);
				}
			}
		}
		&.is-inactive {
			.feature-links-item {
				&-overlay {
					opacity: 0.6;
				}
				&-label {
					opacity: 0.1;
				}
				&-content {
					opacity: 0.1;
				}
				&-image {
					opacity: 0.0;
					@include breakpoint(small down) {
						opacity: 0.5;
					}
				}
			}
		}
	}
}
