.inview-item {
	display: block;
	position: relative;
	&-content {
		display: block;
		position: relative;
		transition: all 0.8s get-easing(in-out-circ);
	}
	&.type-text {
		.inview-item {
			&-content {
				opacity: 1.0;
			}
		}
	}
	&.type-image-fade {
		.inview-item {
			&-content {
				opacity: 1.0;
				transform: translatex(0px);
			}
		}
	}
	&.type-image {
		overflow: hidden;
		.inview-item {
			&-content {
				transform: translatex(0px);
			}
		}
		&:after, 
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: get-neutral-color(98%);
			transition: all 0.5s get-easing(in-out-circ);
			transform: translatex(100%);
			z-index: 2;
		}
		&:after {
			transition-delay: 0.05s;
			background-color: get-neutral-color(88%);
			z-index: 1;
		}	
	}
	&.is-loading {
		&.type-image-fade {
			.inview-item {
				&-content {
					opacity: 0.0;
				}
			}
		}
		&.type-text {
			.inview-item {
				&-content {
					opacity: 0.0;
					transform: translatex(0px);
				}
			}
		}
		&.type-image {
			.inview-item {
				&-content {
					transform: translatex(40px);
				}
			}
			&:after,
			&:before {
				transform: translatex(0%);
			}
		}
	}
}
