// ===============================
//
// Button
//
// ===============================

	//
	// Base
	//

		.button,	
		[type="submit"] {
			display: inline-block;
			position: relative;
			vertical-align: middle;
			line-height: $global-lineheight;
			transition: $global-transition;
			appearance: none;
			border: none;
			outline: none;
			text-decoration: none;
			cursor: pointer;
			border-radius: $global-radius;
			margin: 0;
			border-radius: rem-calc(3);
			padding: {
				top: $global-padding;
				bottom: $global-padding;
				left: $global-padding * 2.5;
				right: $global-padding * 2.5;												
			};
			&:before {
				content: '';
				width: 100%;
				height: 100%;
				border-radius: rem-calc(3);
				border: rem-calc(2) solid get-color(white);
				z-index: 1;
				position: absolute;
				top: 0;
				left: 0;
			}
			&:after {
				content: '';
				width: 100%;
				height: 100%;
				border-radius: rem-calc(3);
				z-index: 2;
				position: absolute;
				top: 0;
				left: 0;
				transition: all 0.35s get-easing(in-out-circ);
				background: linear-gradient(90deg, get-neutral-color(98%), get-neutral-color(88%));
				background-size: 200% 200%;
				animation: gradient 4s alternate infinite;
				clip-path: circle(0% at 20% 120%);
			}
			.button-text {
				position: relative;
				display: block;
				overflow: hidden;
				z-index: 3;
				transition: $global-transition;
				span {
					display: block;
					transition: $global-transition;
					transition-delay: 0.1s;
					width: 100%;
					text-align: center;
					font-size: rem-calc(10);
					font-weight: $global-weight-bold;
					text-transform: uppercase;
				}
				span:first-child {
					position: relative;
				}
				span:last-child {
					top: 0;
					left: 0;
					position: absolute;
					transform: translatey(105%);
				}
			}
			&:hover {
				transform: translatey(-2px);
				&:after {
					clip-path: circle(120% at 20% 120%);
				}
				.button-text {
					span:first-child {
						transform: translatey(-105%);
					}
					span:last-child {
						transform: translatey(0%);
					}
				}
			}
		}
		
	//
	// Colors
	//
	
		.button,	
		button,
		[type="submit"] {
			// Primary
			@include button-color(get-color(primary), get-color(white));
			// Secondary
			&.colour-background-secondary {
				@include button-color(get-color(secondary), get-color(white));	
			}
			// Dark Grey
			&.colour-background-secondary {
				@include button-color(get-color(darkGrey), get-color(darkGrey));	
			}
			// Tertiary
			&.colour-background-tertiary {
				@include button-color(get-color(tertiary), get-color(white));	
			}
			&.colour-white-border-white {
				background-color: transparent;
				color: get-color(white);
				border: none;
				&:before {
					border-color: get-color(white);
				}
				&:after {
					background: linear-gradient(90deg, get-neutral-color(98%), get-neutral-color(88%));
					background-size: 200% 200%;
				}
				.button-text {
					span:last-child {
						@extend .colour-gradient;
					}
				}
			}
			&.colour-white-border-gradient {
				background-color: transparent;
				color: get-color(primary);
				border: none;
				&:before {
					border: none;
					border-left: rem-calc(2) solid get-color(primary);
					border-right: rem-calc(2) solid get-color(secondary);
					background-image: -webkit-gradient(linear, 0 0, 100% 0, from(get-color(primary)), to(get-color(secondary)));
					background-image: 
					  linear-gradient(90deg, get-color(primary), get-color(secondary)),
					  linear-gradient(90deg, get-color(primary), get-color(secondary));
					background-size: 100% rem-calc(2);
					background-position: 0 0, 0 100%;
					background-repeat: no-repeat; 
				}
				&:after {
					background: linear-gradient(90deg, get-color(primary), get-color(tertiary), get-color(secondary));
					background-size: 200% 200%;
				}
				.button-text {
					span:last-child {
						color: get-color(white);
					}
				}
			}
			&.colour-gradient-border-gradient {
				background-color: transparent;
				color: get-color(white);
				border: none;
				&:before {
					border: none;
					border-left: rem-calc(2) solid get-color(primary);
					border-right: rem-calc(2) solid get-color(secondary);
					background-image: -webkit-gradient(linear, 0 0, 100% 0, from(get-color(primary)), to(get-color(secondary)));
					background-image: 
					  linear-gradient(90deg, get-color(primary), get-color(secondary)),
					  linear-gradient(90deg, get-color(primary), get-color(secondary));
					background-size: 100% rem-calc(2);
					background-position: 0 0, 0 100%;
					background-repeat: no-repeat; 
				}
				&:after {
					background: linear-gradient(90deg, get-color(primary), get-color(tertiary), get-color(secondary));
					background-size: 200% 200%;
				}
				.button-text {
					span:first-child {
						@extend .colour-gradient;
					}
					span:last-child {
						color: get-color(white);
					}
				}
			}
			&.dark-gradient-border-gradient {
				background-color: transparent;
				color: get-color(darkGrey);
				border: none;
				&:before {
					border: none;
					border-left: rem-calc(2) solid get-color(darkGrey);
					border-right: rem-calc(2) solid get-color(darkGrey);
					background-image: -webkit-gradient(linear, 0 0, 100% 0, from(get-color(darkGrey)), to(get-color(darkGrey)));
					background-image: 
					  linear-gradient(90deg, get-color(darkGrey), get-color(darkGrey)),
					  linear-gradient(90deg, get-color(darkGrey), get-color(darkGrey));
					background-size: 100% rem-calc(2);
					background-position: 0 0, 0 100%;
					background-repeat: no-repeat; 
				}
				&:after {
					background: linear-gradient(90deg, get-color(darkGrey), get-color(darkGrey), get-color(darkGrey));
					background-size: 200% 200%;
				}
				.button-text {
					span:first-child {
						color: get-color(darkGrey);
					}
					span:last-child {
						color: get-color(white);
					}
				}
			}
		}	
		
	//
	// Sizes & Types
	//
	
		.button,	
		button,
		[type="submit"] {
			// Large
			&.size-large {
			}
			// Small
			&.size-small {
			}
			// Disabled
			&.type-disabled {
				opacity: 0.4;
				cursor: not-allowed;
			}
			// Text
			&.type-text {
				display: inline-block;
				position: relative;
				padding: 0;
				background-color: transparent;
				overflow: hidden;
				padding-bottom: rem-calc(7);
				border-radius: 0;
				transform: translatey(-2px);
				span {
					text-transform: none;
					font-size: rem-calc(13);
					font-weight: $global-weight-normal;
				}
				&:before {
					display: none;
				}
				&:after {
					content: '';
					width: 100%;
					height: rem-calc(4);
					position: absolute;
					border-radius: 0;
					top: auto;
					bottom: 0;
					left: 0;
					clip-path: none;
					background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
					background-size: 200% 200%;
					animation: gradient 4s alternate infinite;
					transform: translatey(2px);
					transition-delay: 0.1s;
				}
				&:hover,
				&:focus,
				&:active {
					transform: translatey(-2px);
					.button-text {
						transform: translatey(-3px);
					}
					&:after {
						transform: translatey(0px);
					}
					box-shadow: none;
				} 
				
			}
		}
		
	//
	// Group
	//
	
		.button-group {
			.button,	
			button,
			[type="submit"] {
				margin-right: $global-margin / 4;
				&:last-child {
					margin-right: 0;
				}
			}
		}