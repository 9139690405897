.site-footer {
	&-heading {
		font-weight: $global-weight-normal;
		color: get-color(primary);
		margin-bottom: $global-margin / 2;
	}
	&-heading-small {
		font-weight: $global-weight-bold;
		color: get-color(white);
		margin-bottom: $global-margin / 2;
	}
	&-text {
		margin-bottom: $global-margin * 2;
		p {
			font-size: rem-calc(14);
			line-height: rem-calc(21);
			color: get-color(bodyLight);
			&:last-child {
				margin-bottom: 0;
			}
		}
		a {
			color: get-color(white);
			text-decoration: underline;
			&:hover {
				color: get-color(bodyLight);
			}
		}
	}
	&-links {
		display: flex;
		flex-direction: column;
		@include breakpoint(medium down) {
			flex-direction: initial;
			flex-wrap: wrap;
		}
		@include breakpoint(small down) {
			flex-direction: column;
			flex-wrap: initial;
		}
		a {
			font-size: rem-calc(14);
			line-height: rem-calc(22);
			color: get-color(bodyLight);
			@include breakpoint(medium down) {
				margin-right: $global-margin * 1.5;
				margin-bottom: $global-margin / 2;
			}
			@include breakpoint(small down) {
				margin-right: 0;
				margin-bottom: 0;
			}
			&:hover {
				color: get-color(white);
			}
		}
	}
	&-copyright {
		p {
			font-size: rem-calc(13);
			color: get-color(bodyLight);
		}
		a {
			color: get-color(bodyLight);
			text-decoration: underline;
			margin-left: rem-calc(10);
			@include breakpoint(small down) {
				display: block;
				margin-left: 0;
				margin-bottom: rem-calc(2);
			}
			&:hover {
				color: get-color(white);
			}
		}
	}
	&-social {
		display: flex;
		flex-direction: row;
		a {
			display: flex;
			align-items: center;
			position: relative;
			justify-content: center;
			font-size: rem-calc(26);
			color: get-color(white);
			width: rem-calc(40);
			height: rem-calc(40);
			border-radius: 100%;
			background-color: get-color(darkGrey);
			margin: rem-calc(4);
			@include breakpoint(small down) {
				margin-left: 0;
				margin-right: rem-calc(8);
			}
			i {
				position: relative;
				z-index: 2;
			}
			&:after {
				content: '';
				width: 100%;
				height: 100%;
				z-index: 0;
				position: absolute;
				top: 0;
				right: 0;
				border-radius: 100%;
				z-index: 1;
				transition: all 0.3s get-easing(in-out-circ);
			}
			&:after {
				background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
				background-size: 200% 200%;
				animation: gradient 4s alternate infinite;
				clip-path: circle(0% at 20% -40%);
			}
			&:hover {
				transform: scale(.95);
				color: get-color(primary);
			}
		}
	}
}


@media (max-width: 820px) {
	.hide-on-medium-down
	{
		display: none;
	}
  }

  