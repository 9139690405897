.work-image {
	display: block;
	position: relative;
	height: rem-calc(500);
	@include breakpoint(small down) {
		height: auto;
	}
	img {
		object-fit: contain;
		object-position: center center;
		width: 100%;
		height: 100%;
	}
	//transform: translatex(-30%);
	&.size-large {
	//	transform: scale(1.4) translatex(-30%);
	}
	&.align-right {
		//transform: translatex(30%);
		&.size-large {
		//	transform: scale(1.4) translatex(30%);
		}
	}
}
