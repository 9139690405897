@font-face {
  font-family: "F37Bolton";
  src: url('../assets/fonts/F37Bolton-Bold/F37Bolton-Bold.eot');
  src: url('../assets/fonts/F37Bolton-Bold/F37Bolton-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/F37Bolton-Bold/F37Bolton-Bold.woff2') format('woff2'),
    url('../assets/fonts/F37Bolton-Bold/F37Bolton-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: "F37Bolton";
  src: url('../assets/fonts/F37Bolton-Regular/F37Bolton-Regular.eot');
  src: url('../assets/fonts/F37Bolton-Regular/F37Bolton-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/F37Bolton-Regular/F37Bolton-Regular.woff2') format('woff2'),
    url('../assets/fonts/F37Bolton-Regular/F37Bolton-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "F37Bolton";
  src: url('../assets/fonts/F37Bolton-Light/F37Bolton-Light.eot');
  src: url('../assets/fonts/F37Bolton-Light/F37Bolton-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/F37Bolton-Light/F37Bolton-Light.woff2') format('woff2'),
    url('../assets/fonts/F37Bolton-Light/F37Bolton-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "F37Bolton";
  src: url('../assets/fonts/F37Bolton-Thin/F37Bolton-Thin.eot');
  src: url('../assets/fonts/F37Bolton-Thin/F37Bolton-Thin.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/F37Bolton-Thin/F37Bolton-Thin.woff2') format('woff2'),
    url('../assets/fonts/F37Bolton-Thin/F37Bolton-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
