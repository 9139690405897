// ===============================
//
// Form
//
// ===============================

	//
	// Labels
	//

		label {
			display: inline-block;
			vertical-align: top;
			font-weight: 500;
			margin-bottom: rem-calc(3);
		}

		label[for] {
			cursor: pointer;
		}

	//
	// Autofill
	//

		:-webkit-autofill,
		:-webkit-autofill:hover,
		:-webkit-autofill:focus {
		  -webkit-text-fill-color: get-color(white);
		  box-shadow: 0 0 0px 1000px get-color(darkGrey) inset !important;
		  border: rem-calc(1) solid rgba(get-color(white), 0.2) !important;
		  transition: background-color 5000s ease-in-out 0s !important;
		}

	//
	// Placeholder
	//

		::placeholder {
			color: rgba(get-color(white), 1);
		}

	//
	// Fields
	//

		// Inputs, Select, Textarea

		input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]),
		select,
		textarea {
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			outline: none;
			appearance: none;
			margin: 0;
			padding: $global-padding / 1.05 $global-padding * 1.2;
			border: rem-calc(1) solid rgba(get-color(white), 0.2);
			border-radius: $global-radius;
			background-color: get-color(darkGrey);
			color: get-color(white);
			line-height: $global-lineheight;
			height: auto;
			transition: $global-transition;
			box-shadow: none;
			box-sizing: border-box;
			font-size: rem-calc(13);
			&:hover,
			&:active {
				border-color: get-neutral-color(65%);
			}
			&:focus {
				border-color: get-color(white);
			}
		}

		input[type='file'] {
			display:none;
			margin: 0;
		}
		.field-container.type-file label {
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			outline: none;
			appearance: none;
			margin: 0;
			padding: $global-padding / 1.05 $global-padding * 1.2;
			border: rem-calc(1) solid rgba(get-color(white), 0.2);
			border-radius: $global-radius;
			background-color: transparent;
			color: get-color(white);
			line-height: $global-lineheight;
			height: auto;
			transition: $global-transition;
			box-shadow: none;
			box-sizing: border-box;
			font-size: rem-calc(13);
			&:before {
				@extend .fas;
				content: "\f382";
				font-family: $font-family-icon;
				position: absolute;
				top: 50%;
				right: $global-padding * 1.15;
				pointer-events: none;
				font-size: rem-calc(15);
				color: get-color(bodyLight);
				transform: translateY(-50%);
				z-index: 2;
			}
			&:hover,
			&:active {
				border-color: get-neutral-color(65%);
			}
			&:focus {
				border-color: get-color(white);
			}
		}

		textarea {
			height: rem-calc(200);
		}

		// Field Note

		.field-note {
			font-size: rem-calc(12);
			color: rgba(get-color(white), 0.5);
		}

		// Checkbox, Radio

		input[type="checkbox"],
		input[type="radio"],
		 {
			display: inline-block;
			vertical-align: middle;
			margin: 0;
			padding: 0;
			height: auto;
		}

		input[type="checkbox"] + label,
		input[type="radio"] + label {
			vertical-align: middle;
			margin-bottom: 0;
		}

		// Select

		.field-select {
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			position: relative;
			&:after {
				@extend .fas;
				content: "\f0d7";
				font-family: $font-family-icon;
				position: absolute;
				top: 50%;
				right: $global-padding * 1.2;
				pointer-events: none;
				font-size: rem-calc(12);
				transform: translateY(-50%);
			}
		}

		// Checkbox Group

		.field-container.type-checkbox_group {
			display: flex;
			flex-wrap: wrap;
			label {
				position: relative;
				border: rem-calc(1) solid rgba(get-color(white), 0.2);
				background-color: get-color(darkGrey);
				border-radius: $global-radius;
				padding: $global-padding / 1.05 $global-padding * 1.2;
				display: inline-block;
				margin-right: $global-margin;
				margin-bottom: $global-margin;
				display: flex;
				align-items: center;
				font-size: rem-calc(13);
				color: get-color(white);
				transition: $global-transition;
				cursor: pointer;
				&:before,
				&:after {
					content: '';
					width: rem-calc(15);
					height: rem-calc(15);
					z-index: 0;
					position: absolute;
					top: rem-calc(15);
					right: rem-calc(15);
					border-radius: 100%;
					z-index: 1;
					transition: all 0.4s get-easing(in-out-circ);
				}
				&:before {
					border: rem-calc(1) solid rgba(get-color(white), 0.2);
				}
				&:after {
					background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
					background-size: 200% 200%;
					animation: gradient 4s alternate infinite;
					clip-path: circle(0% at 20% -40%);
				}
				input {
					display: none;
					margin-right: rem-calc(4);
				}
				&:nth-child(odd) {
					width: calc(50% - #{$global-margin});
				}
				&:nth-child(even) {
					width: 50%;
					margin-right: 0;
				}
				&:hover,
				&:active {
					border-color: get-neutral-color(65%);
					&:before {
						transform: scale(0.7);
					}
				}
				&.is-active {
					border-color: get-color(white);
					padding-left: $global-padding * 1.5;
					&:before {
						transform: scale(1);
					}
					&:after {
						clip-path: circle(170% at 20% -40%);
						transform: scale(0.6);
					}
				}
			}
		}

	//
	// Datepicker
	//

		.field-container.type-datepicker {
			&:after {
				@extend .fas;
				content: "\f073";
				font-family: $font-family-icon;
				position: absolute;
				top: 50%;
				right: $global-padding * 1.2;
				pointer-events: none;
				font-size: rem-calc(12);
				transform: translateY(-50%);
			}
		}

	//
	// Containers
	//

		.field-container {
			margin-bottom: $global-margin;
			position: relative;
			&.type-light {
				input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
				select,
				textarea {
					border: rem-calc(1) solid rgba(get-color(white), 0.2);
					background-color: transparent;
					color: get-color(white);
					&:hover {
						border-color: rgba(get-color(white), 0.5);
					}
					&:focus,
					&:active {
						border-color: get-color(white);
					}
				}
				::placeholder {
					color: rgba(get-color(white), 1);
				}
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.validate-alert {
			position: absolute;
			font-weight: $global-weight-bold;
			text-transform: uppercase;
			font-size: rem-calc(12);
			bottom: rem-calc(15);
			right: rem-calc(15);
			pointer-events: none;
			z-index: 3;
			width: rem-calc(18);
			height: rem-calc(18);
			border-radius: 100%;
			color: get-color(darkGrey);
			text-align: center;
			padding-top: rem-calc(4);
			background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
			background-size: 200% 200%;
			animation: gradient 4s alternate infinite;
		}
		.validate-summary {
			display: none;
		}

	//
	// Grid
	//

		.field-grid {
			display: flex;
			flex-wrap: wrap;
			flex-flow: row wrap;
			align-items: center;
			justify-content: space-between;
			&.type-dark {
				:-webkit-autofill,
				:-webkit-autofill:hover,
				:-webkit-autofill:focus {
				  -webkit-text-fill-color: get-color(darkGrey);
				  box-shadow: 0 0 0px 1000px get-color(white) inset !important;
				  border: rem-calc(1) solid rgba(get-color(darkGrey), 0.2) !important;
				  transition: background-color 5000s ease-in-out 0s !important;
				}
				::placeholder {
					color: rgba(get-color(darkGrey), 0.5);
				}
				input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
				select,
				textarea {
					background-color: get-color(white);
					color: get-color(heading);
					border-color: rgba(get-color(darkGrey), 0.2);
					&:hover,
					&:active {
						border-color: rgba(get-color(darkGrey), 0.6);
					}
					&:focus {
						border-color: get-color(darkGrey);
					}
				}
				.validate-alert {
					color: get-color(white);
				}
				.field-checkbox {
					span {
						color: get-color(body);
						a {
							color: get-color(body);
							&:hover {
								color: get-color(black);
							}
						}
					}
				}
			}
			.field-container {
				float:left;
				&:nth-child(odd) {
					width: calc(50% - #{$global-margin});
					@include breakpoint(small down) {
						width: 100%;
					}
				}
				&:nth-child(even) {
					width: 50%;
					@include breakpoint(small down) {
						width: 100%;
					}
				}
				&.type-size-full {
					width: 100%;
					margin-right: 0;
				}
				&:last-child,
				&:nth-last-child(-n+2):not(.type-size-full) {
					margin-bottom: 0;
				}
			}
		}

	//
	// Row
	//

		.fields-row {
			display: block;
			margin-bottom: $global-margin;
			&.type-inline {
				display: flex;
				@include breakpoint(medium down) {
					flex-wrap: wrap;
				}
				.field-container {
					flex: 1;
					margin-right: rem-calc(20);
					margin-bottom: 0;
					@include breakpoint(medium down) {
						flex: none;
						width: 100%;
						margin-right: 0;
						margin-bottom: $global-margin / 2;
					}
					@include breakpoint(small down) {
						flex: none;
						width: 100%;
						margin-right: 0;
						margin-bottom: $global-margin / 2;
					}
					&:last-child {
						margin-right: 0;
						flex-shrink: 0;
						max-width: rem-calc(200);
						@include breakpoint(medium down) {
							max-width: none;
						}
					}
				}
				.button,
				button,
				[type="submit"] {
					width: 100%;
					padding: {
						top: $global-padding * 1.1;
						bottom: $global-padding * 1.2;
						left: $global-padding * 2.5;
						right: $global-padding * 2.5;
					}
				}
			}
		}

	//
	// Field Hidden
	//

		.field-hidden {
			display: block;
			position: relative;
			opacity: 0.0;
			transition: $global-transition;
			transform: translatey(5px);
			&.is-active {
				opacity: 1.0;
				transform: translatey(0px);
			}
		}


	//
	// Field Checkbox
	//

		.field-checkbox {
			margin-bottom: $global-margin / 1.5;
			@include breakpoint(medium down) {
				margin-bottom: 0;
			}
			input {
				vertical-align: top;
				transform: translatey(6px);
			}
			span {
				font-weight: $global-weight-normal;
				font-size: rem-calc(12);
				color: rgba(get-color(white), 0.5);
				@include breakpoint(small down) {
					width: rem-calc(200);
					display: inline-block;
				}
				a {
					color: rgba(get-color(white), 0.5);
					text-decoration: underline;
					&:hover {
						color: get-color(white);
					}
				}
			}
		}


	//
	// Form Steps
	//

		.form-steps {
			display: block;
			margin-bottom: $global-margin;
			ul {
				display: flex;
				flex-direction: row;
				list-style: none;
				margin: 0;
				padding: 0;
			}
			li {
				display: inline-block;
				font-size: rem-calc(13);
				margin-right: $global-margin;
			}
			a {
				color: get-color(white);
				text-decoration: underline;
			}
		}
