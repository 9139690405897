$socialSlideshowHeight: rem-calc(300);

.social-slideshow {
	display: block;
	position: relative;
	width: 100%;
	overflow: hidden;
	&-slide {
		display: block;
		position: relative;
		cursor: pointer;
		&:hover {
			.social-slideshow {
				&-text {
					transform: translatey(-5px);
				}
				&-image {
					&:before {
						clip-path: circle(145% at 50% -40%);
					}
					img {
						transform: scale(1.1);
					}
				}
			}
		}
	}
	&-image {
		display: block;
		position: relative;
		overflow: hidden;
		z-index: 1;
		transition: $global-transition;
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
			background-size: 200% 200%;
			animation: gradient 6s alternate infinite;
			transition: all 0.6s get-easing(in-out-circ);
			opacity: 0.7;
			clip-path: circle(0% at 50% -40%);
		}
		img {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
			transition: $global-transition;
		}
	}
	&-overlay {
		width: 102%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		background: linear-gradient(to bottom, rgba(0,0,0,0.0) 30%,rgba(0,0,0,1.0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
		transition: $global-transition;
	}
	&-text {
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 3;
		width: 100%;
		padding: $global-padding * 2;
		font-size: rem-calc(12);
		color: get-color(white);
		transition: $global-transition;
		transition-delay: 0.15s;
	}
	// Loader Height
	.slideshow {
		&-loader {
			height: $socialSlideshowHeight;
		}
		&[data-slideshow-type="socialSlideshow"] {
			height: $socialSlideshowHeight;
			&.owl-loaded {
				height: auto;
			}
		}
	}
	.owl-carousel {
		.owl-nav {
			position: relative;
			top: 0;
			left: 0;
			transform: translate(0);
			display: flex;
			margin-top: $global-margin;
		}
		button.owl-next,
		button.owl-prev {
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			margin-right: $global-margin / 1.5;
		}
	}
}
