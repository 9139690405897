// ===============================
//
// Helper
//
// ===============================

	:root {

		//
		// Typography
		//

			.font-size-larger { font-size: rem-calc($global-font-size-px * 1.5) }
			.font-size-large { font-size: rem-calc($global-font-size-px * 3) }
			.font-size-largest { font-size: rem-calc($global-font-size-px * 5) }

			.font-size-smaller { font-size: rem-calc($global-font-size-px - 1.5) }
			.font-size-small { font-size: rem-calc($global-font-size-px - 3) }
			.font-size-smallest { font-size: rem-calc($global-font-size-px - 5) }

		//
		// Flexbox
		//

			.flexbox-equal-height {
				display: flex;
				flex-wrap: nowrap;
			}

			.flexbox-vertical-align {
				display: flex;
				align-items: center;
			}

			.flexbox-space-between {
				display: flex;
				justify-content: space-between;
			}

			.flexbox-wrap {
				display: flex;
				flex-wrap: wrap;
			}

		//
		// Align
		//

			.align-left {
				text-align: left;
			}

			.align-center {
				text-align: center;
			}

			.align-right {
				text-align: right;
			}

		//
		// Gutters
		//

			.gutter-top {
				&-margin { @include gutter('top'); }
				&-padding { @include gutter('top', 'padding'); }
			}

			.gutter-right {
				&-margin { @include gutter('right');	 }
				&-padding { @include gutter('right', 'padding');	 }
			}

			.gutter-bottom {
				&-margin { @include gutter('bottom'); }
				&-padding { @include gutter('bottom', 'padding'); }
			}

			.gutter-left {
				&-margin { @include gutter('left'); }
				&-padding { @include gutter('left', 'padding'); }
			}

		//
		// Padding & Margin
		//

			@mixin box-model-properties($property, $value, $i){
				&-#{$i}x { #{$property}: #{$value * $i} }
				&-top-#{$i}x { #{$property}-top: #{$value * $i} }
				&-right-#{$i}x { #{$property}-right: #{$value * $i} }
				&-bottom-#{$i}x { #{$property}-bottom: #{$value * $i} }
				&-left-#{$i}x { #{$property}-left: #{$value * $i} }
				&-top-bottom-#{$i}x {
					#{$property}: {
						top: #{$value * $i};
						bottom: #{$value * $i};
					}
				}
				&-left-right-#{$i}x {
					#{$property}: {
						left: #{$value * $i};
						right: #{$value * $i};
					}
				}
			}

			// General Padding & Margin

			@for $i from 0 through $global-margin-padding-length {
				.margin { @include box-model-properties('margin', $global-margin, $i); }
				.padding { @include box-model-properties('padding', $global-padding, $i); }
			}

			// Breakpoint Padding & Margin

			@each $breakpoint, $value in map-reverse($breakpoints) {
				@for $i from 0 through $global-margin-padding-length {
					@include breakpoint($breakpoint 'down') {
						.#{$breakpoint} {
							&-margin { @include box-model-properties('margin', $global-margin, $i); }
							&-padding { @include box-model-properties('padding', $global-padding, $i); }
						}
					}
				}
			}

			.margin-bottom-05x {
				margin-bottom: $global-margin / 2;
			}

		//
		// Border
		//

			.border-0 {
				border: 0;
			}

			.border-top-0 {
				border-top-width: 0;
			}

			.border-right-0 {
				border-right-width: 0;
			}

			.border-bottom-0 {
				border-bottom-width: 0;
			}

			.border-left-0 {
				border-left-width: 0;
			}

			.border-bottom-1 {
				border-bottom: rem-calc(1) solid;
			}

			.border-1 {
				border: rem-calc(1) solid;
			}

		//
		// Position
		//

			.position-absolute {
				position: absolute;
			}

			.position-relative {
				position: relative;
			}

			.position-fixed {
				position: fixed;
			}

			.position-static {
				position: static;
			}

			.position-sticky {
				position: sticky;
			}

		//
		// Radius
		//

			.radius {
				border-radius: $global-radius;
			}

			.radius-round {
				border-radius: 100%;
			}

		//
		// Webfont Loader FOUT Fix
		//

			.wf-loading {
			    visibility: hidden;
			}

			.wf-active,
			.wf-inactive {
			    visibility: visible;
			}

		//
		// Colours
		//

			@each $colour, $value in $foundation-palette {

				.colour-border-#{$colour} {
					border-color: #{$value};
				}

				.colour-#{$colour} {
					color: #{$value};
				 }

				.colour-background-#{$colour} {
					background-color: #{$value};
				}

				.colour-fill-#{$colour} {
					@include svg-fill($value);
				}

			}

		//
		// Responsive
		//

			.medium {
				@include breakpoint(medium down) {
					&-flexbox-wrap {
						flex-wrap: wrap;
					}
					&-order-1 {
						order: 1;
					}
					&-order-2 {
						order: 2;
					}
				}
			}


			.small {
				&-block {
					display: none !important;
				}
				@include breakpoint(small down) {
					&-flexbox-wrap {
						flex-wrap: wrap;
					}
					&-order-1 {
						order: 1;
					}
					&-order-2 {
						order: 2;
					}
					&-align-center {
						text-align: center;
					}
					&-false {
						display: none !important;
					}
					&-block {
						display: block !important;
					}
				}
			}

	}
