.statistics {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	position: relative;
	display: flex;
	border:none;
	align-items: center;
	justify-content: space-between;
	padding: $global-padding * 4 $global-padding * 5;
	@include breakpoint(medium down) {
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: center;
		padding: $global-padding * 2 $global-padding * 2;
	}
	&-item {
		display: inline-block;
		text-align: center;
		@include breakpoint(medium down) {
			width: 50%;
			margin-bottom: $global-margin * 2;
			&:last-child,
			&:nth-last-child(2) {
				margin-bottom: 0;
			}
		}
		@include breakpoint(small down) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin-bottom: $global-margin * 2;
			&:last-child {
				margin-bottom: 0;
			}
			&:nth-last-child(2) {
				margin-bottom: $global-margin * 2;
			}
		}
		&-content {
			@include breakpoint(small down) {
				flex: 1;
			}
		}
		&-icon {
			display: block;
			position: relative;
			margin-bottom: $global-margin / 2;
			@include breakpoint(small down) {
				flex: 1;
				text-align: left;
			}
			svg {
				max-width: rem-calc(50);
				position: relative;
				z-index: 2;
				width: 100%;
				height: 100%;
				transition: $global-transition;
				fill: get-color(primary);
			}
		}
		&-number {
			@extend h2;
			font-size: 3rem;
			color:get-color(primary) !important;
			margin-bottom: $global-margin / 10;
		}
		&-label {
			font-size: rem-calc(20);
			color:get-color(white);
		}
	}
}
