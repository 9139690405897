.careers-items {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
.careers-item {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	@extend .medium-12;
	padding: 0;
	margin-bottom: $global-margin;
	border: rem-calc(1) solid rgba(get-color(black), 0.1);
	background-color: get-color(white);
	padding: $global-padding $global-padding * 2;
	transition: $global-transition;
	transition-delay: 0.1s;
	@include breakpoint(small down) {
		padding: $global-padding;
		width: 100%;
	}
	&:last-child {
		margin-bottom: 0;
	}
	&:after {
		content: '';
		width: 100%;
		height: 100%;
		z-index: 0;
		position: absolute;
		top: 0;
		left: 0;
		transition: all 0.45s get-easing(in-out-circ);
		background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
		background-size: 200% 200%;
		animation: gradient 4s alternate infinite;
		clip-path: circle(0% at 20% -40%);
	}
	&-heading {
		@extend h4;
		margin: 0;
		position: relative;
		z-index: 2;
		transition: $global-transition;
		@include breakpoint(small down) {
			font-size: rem-calc(18);
		}
	}
	&-icon {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
		width: rem-calc(47);
		height: rem-calc(47);
		border-radius: 100%;
		transition: $global-transition;
		transition-delay: 0.15s;
		@include breakpoint(small down) {
			width: rem-calc(30);
			height: rem-calc(30);
		}
		i {
			position: relative;
			z-index: 3;
			color: get-color(darkGrey);
			font-size: rem-calc(15);
			transition: $global-transition;
		}
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			border-radius: 100%;
			border: rem-calc(2) solid rgba(get-color(black), 0.05);
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
			transition: $global-transition;
			@include breakpoint(small down) {
				border: none;
			}
		}
		&:after {
			content: '';
			width: 100%;
			height: 100%;
			border-radius: 100%;
			z-index: 2;
			position: absolute;
			top: 0;
			left: 0;
			transition: all 0.4s get-easing(in-out-circ);
			background: linear-gradient(90deg, get-neutral-color(98%), get-neutral-color(88%));
			background-size: 200% 200%;
			animation: gradient 4s alternate infinite;
			clip-path: circle(0% at 120% -5%);
			transition-delay: 0.2s;
		}
	}
	&:hover {
		border-color: transparent;
		transform: translatex(3px);
		&:after {
			clip-path: circle(120% at 20% -40%);
		}
		.careers-item {
			&-heading {
				color: get-color(white);
				transform: translatex(5px);
			}
			&-icon {
				transform: scale(0.9);
				&:before {
					border-color: get-color(white);
				}
				&:after {
					clip-path: circle(150% at 120% -5%);
				}
			}
		}
	}
}
