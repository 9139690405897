.testimonial-slideshow {
	display: block;
	position: relative;
	width: 100%;
	overflow: hidden;
	//height: rem-calc(650);
	@include breakpoint(large down) {
		height: auto;
	}
	&-slide {
		display: block;
		position: relative;
	}
	&-image {
		display: block;
		position: relative;
		overflow: hidden;
		img {
			object-fit: cover;
			object-position: center center;
			width: 100%;
			height: 100%;
		}
		&:hover {
			transform: scale(0.95);
			.testimonial-slideshow {
				transform: scale(0.95);
				&-icon {
					transform: scale(1.1);
					&:after {
						clip-path: circle(120% at 20% -5%);
					}
					i {
						transform: scale(0.8);
					}
					span {
						//opacity: 1.0;
						transform: translatex(140%) translatey(-50%);
					}
				}
			}
		}
	}
	&-logo {
		svg {
			max-width: rem-calc(100);
			position: relative;
			z-index: 2;
			width: 100%;
			height: 100%;
			transition: $global-transition;
			fill: get-color(bodyLight);
		}
	}
	&-label {
		color: get-color(white);
		font-size: rem-calc(14);
		span {
			font-weight: $global-weight-bold;
		}
	}
	&-icon {
		display: block;
		position: absolute;
		top: calc(50% - #{rem-calc(40)});
		left: calc(50% - #{rem-calc(40)});
		z-index: 3;
		width: rem-calc(80);
		height: rem-calc(80);
		color: get-color(white);
		display: flex;
		align-items: center;
		justify-content: center;
		transition: $global-transition;
		transition-delay: 0.15s;
		span {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translatex(130%) translatey(-50%);
			font-size: rem-calc(11);
			font-weight: $global-weight-bold;
			text-transform: uppercase;
			color: get-color(white);
			opacity: 0.0;
			transition: $global-transition;
			transition-delay: 0.3s;
		}
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			border-radius: 100%;
			border: rem-calc(2) solid get-color(white);
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
		}
		&:after {
			content: '';
			width: 100%;
			height: 100%;
			border-radius: 100%;
			z-index: 2;
			position: absolute;
			top: 0;
			left: 0;
			transition: all 0.4s get-easing(in-out-circ);
			background: linear-gradient(90deg, get-color(secondary), get-color(tertiary), get-color(primary));
			background-size: 200% 200%;
			animation: gradient 4s alternate infinite;
			clip-path: circle(0% at 20% -5%);
		}
		i {
			position: relative;
			z-index: 3;
			transition: $global-transition;
			transition-delay: 0.05s;
		}
	}
	// Loader Height
	.slideshow {
		&-loader {
			height: rem-calc(650);
			@include breakpoint(small down) {
				height: rem-calc(350);
			}
		}
		&[data-slideshow-type='testimonialSlideshow'] {
			height: rem-calc(650);
			@include breakpoint(small down) {
				height: rem-calc(350);
			}
			&.owl-loaded {
				height: auto;
			}
		}
	}
}
// Transitions
.slideshow[data-slideshow-type="testimonialSlideshow"] {
	.owl-item {
		.testimonial-slideshow {
			&-image {
				transition: $global-transition;
				transition-delay: 0.2s;
				opacity: 0.0;
			}
			&-logo {
				transition: $global-transition;
				transition-delay: 0.25s;
				opacity: 0.0;
				transform: translatey(20px);
			}
			&-text {
				transition: $global-transition;
				transition-delay: 0.3s;
				opacity: 0.0;
				transform: translatey(20px);
			}
			&-label {
				transition: $global-transition;
				transition-delay: 0.35s;
				opacity: 0.0;
				transform: translatey(20px);
			}
		}
	}
	.owl-item.active {
		.testimonial-slideshow {
			&-text {
				opacity: 1.0;
				transform: translatex(0);
			}
			&-label {
				opacity: 1.0;
				transform: translatex(0);
			}
			&-logo {
				opacity: 1.0;
				transform: translatex(0);
			}
			&-image {
				opacity: 1.0;
			}
		}
	}
}
